body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.btn-orange {
  color: #ffffff;
  background-color: #ff851b;
  border-color: #ff851b;
  box-shadow: none;
}

.btn-orange:hover {
  color: #ffffff;
  background-color: #eb7309;
  border-color: #df8107;
}

.btn-orange:focus,
.btn-orange.focus {
  color: #ffffff;
  background-color: #eb7609;
  border-color: #df8107;
  box-shadow: none, 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-orange.disabled,
.btn-orange:disabled {
  color: #ffffff;
  background-color: #ff851b;
  border-color: #ff851b;
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #ffffff;
  background-color: #df8107;
  border-color: #d56f03;
}

.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.modal-xxl{
  width:80%;
  max-width: 1200px ;
}

table.module-table,
th.module-table,
td.module-table {
  border: 1px solid;
  border-radius: 4px;
  border-collapse: separate;
  border-color:darkgrey;
  text-align: center;
}

table.module-table{
  border : none;
}